import React from "react"
import { Icon } from "helpers/getIcon"
import {
  SearchResultsContainer,
  SearchResultsTitleArrowContainer,
  SearchResultsTitle,
  SearchResultsOrgLocation,
  SearchResultsOrganisation,
  SearchResultsLocation,
  SearchResultsExcerpt,
  SearchResultsResultContainer,
  SearchResultsLink,
} from "./style"

const SearchResults = ({ data, internal }) => {
  return (
    <SearchResultsContainer>
      {data &&
        data.map((result, index) => {

          return (
            <SearchResultsResultContainer internal={internal} key={index}>
              <SearchResultsTitleArrowContainer internal={internal}>
                <SearchResultsTitle
                  internal={internal}
                  dangerouslySetInnerHTML={{ __html: result.title }}
                />
                <Icon name={internal ? "arrow-circle" : "arrow-right"} />
              </SearchResultsTitleArrowContainer>
              <SearchResultsOrgLocation internal={internal}>
                <SearchResultsOrganisation>
                  {result?.authors?.map((name, index) => (
                    <>{`${name}${
                      index !== result.authors.length - 1 ? ", " : ""
                    }`}</>
                  ))}
                </SearchResultsOrganisation>
                <SearchResultsLocation>
                  {result?.pb_city && (
                    <>
                      <span
                        dangerouslySetInnerHTML={{ __html: result?.pb_city }}
                      />
                      {","}
                    </>
                  )}
                  {result?.pb_country && (
                    <>
                      {" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: result?.pb_country,
                        }}
                      />
                      {"."}
                    </>
                  )}
                  {result?.pb_name && (
                    <>
                      {" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: result?.pb_name,
                        }}
                      />
                      {"."}
                    </>
                  )}
                  {result?.pb_year && (
                    <>
                      {" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: result?.pb_year,
                        }}
                      />
                      {"."}
                    </>
                  )}
                  {result?.pb_type && (
                    <>
                      {" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: result?.pb_type,
                        }}
                      />
                    </>
                  )}
                  {result?.pb_location && (
                    <>
                      {" "}
                      <span>(Location: {result?.pb_location})</span>
                    </>
                  )}
                </SearchResultsLocation>
              </SearchResultsOrgLocation>
              <SearchResultsExcerpt
                dangerouslySetInnerHTML={{ __html: result.excerpt }}
              />
              {result.uri && <SearchResultsLink to={result.uri} />}
            </SearchResultsResultContainer>
          )
        })}
    </SearchResultsContainer>
  )
}

export default SearchResults
