import styled from "styled-components"
import { device } from "breakpoints"
import AvenueLink from "UI/AvenueLink"

export const SearchResultsContainer = styled.div`
  width: 100%;
`

export const SearchResultsTitleArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${(props) => (props.internal ? "flex-start" : "center")};

  svg {
    width: ${(props) => (props.internal ? "22px" : "26px")} !important;
    height: ${(props) => (props.internal ? "22px" : "26px")} !important;

    @media ${device.desktop} {
      width: ${(props) => (props.internal ? "22px" : "36px")} !important;
      height: ${(props) => (props.internal ? "22px" : "36px")} !important;
    }
  }
`
export const SearchResultsResultContainer = styled.div`
  margin-top: 20px;
  padding-bottom: ${(props) => (props.internal ? "10px" : "25px")};
  border-bottom: 1px solid ${(props) => props.theme.solitude};
  position: relative;

  @media ${device.desktop} {
    margin-top: ${(props) => (props.internal ? "25px" : "30px")};
    padding-bottom: ${(props) => (props.internal ? "5px" : "30px")};
  }
`
export const SearchResultsTitle = styled.h2`

  br {
    display: none;
  }
  ${(props) =>
    props.internal
      ? "font-weight: 500; font-size: 14px; color: #008873; letter-spacing: -0.15px; line-height: 20px;"
      : "font-weight: normal; font-size: 20px; color: #303b45; letter-spacing: 0; line-height: 24px;"};
  width: 90%;

  @media ${device.desktop} {
    ${(props) =>
      props.internal
        ? "font-size: 16px; color: #008873; letter-spacing: -0.17px; line-height: 24px;"
        : "font-weight: normal; font-size: 24px; color: #303b45; letter-spacing: 0; line-height: 28px;"};

    ${SearchResultsResultContainer}:hover & {
      color: ${(props) => props.theme.lightGreen};
    }
  }
`

export const SearchResultsOrgLocation = styled.div`
  display: ${(props) => (props.internal ? "none" : "block")};
  margin-top: 6px;

  @media ${device.desktop} {
    margin-top: 0;
    width: 90%;
  }
`
export const SearchResultsLocation = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #636e78;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  margin-left: 19px;
  display: inline;

  @media ${device.desktop} {
    font-size: 14px;
    line-height: 20px;
    margin-left: 25px;
  }

  ::before {
    content: "";
    position: absolute;
    top: 3px;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    left: -11px;

    background: ${(props) => props.theme.linkWater};
    @media ${device.desktop} {
      left: -13px;
    }
  }
`
export const SearchResultsOrganisation = styled(SearchResultsLocation)`
  color: ${(props) => props.theme.green};
  margin-left: 0;
  ::before {
    display: none;
  }
  @media ${device.desktop} {
    margin-left: 0;
  }
`
export const SearchResultsExcerpt = styled.p`
  font-weight: normal;
  font-size: 14px;
  color: #656f76;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 7px;

  @media ${device.desktop} {
    font-size: 16px;
    line-height: 24px;
    margin-top: 18px;
  }
`

export const SearchResultsLink = styled(AvenueLink)`
  ::before {
    content: "" !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }
`
